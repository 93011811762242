import '../sass/components/_othersProjectsList.scss'
import OtherProjectCard from './cards/OtherProject'

function OthersProjectsList({ projects }) {
    return <div className='others-projects-list'>
        {projects.map((project, i) => {
            return <OtherProjectCard key={i} project={project} color={`color-project-${i % 3 + 1}`} />
        })}
    </div>
}

export default OthersProjectsList;
