import { useRef } from 'react'
import Container, { ContainerFlex, Row } from '../components/Containers';
import SkillsList from '../components/SkillsList'
import MainCard from '../components/cards/Main'
import PortfolioCard from '../components/cards/Portfolio'
import CvCard from '../components/cards/Cv'
import ContactCard from '../components/cards/Contact'
import BestProjectsList from '../components/BestProjectsList'
import OthersProjectsList from '../components/OthersProjectsList'
import IconButton from '../components/IconButton'
import mock from '../services/mock';
import Translator from '../services/translate';
import '../sass/pages/_home.scss';
import icon_arrow_down from '../icons/arrow-down-primary.svg';


function Home() {
    const informations = mock.getMyInfos();
    const skills = mock.getMySkills();
    const bestProjects = mock.getMyBestProjects();
    const othersProjects = mock.getMyOthersProjects();
    const projectsRef = useRef(null);

    function scrollToProjects() {
        projectsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    return (
        <Container id="home-page" width="100" justify="center">
            {/* Header */}
            <Container className="hero-header" width="100" justify="center">
                <ContainerFlex className="hero-header__content" width="max" height="100" justify="center">
                    <Container className='hero-header__content__infos' width="50" gap="3">
                        <Row>
                            <MainCard informations={informations} />
                        </Row>

                        <ContainerFlex className="row-portfolio-cv" width="100" gap="3">
                            <PortfolioCard onClick={scrollToProjects} />
                            <CvCard src={informations['src-cv']} />
                        </ContainerFlex>

                        <Row>
                            <ContactCard informations={informations} />
                        </Row>
                    </Container>

                    <Container className="hero-header__content__skills" width="50" height="100" align="center">
                        <SkillsList skills={skills} />
                    </Container>

                    <IconButton onClick={scrollToProjects} className="icon-go-to-projects" color="secondary" size="large" icon={icon_arrow_down} />
                </ContainerFlex>
            </Container>

            {/* Best projects */}
            <Container refName={projectsRef}>
                <h2 className='section-title'>
                    <Translator tid="HOME.TITLE_SECTION_BEST_PROJECTS" />
                </h2>
                <BestProjectsList projects={bestProjects} />
            </Container>

            {/* Other projects */}
            <Container width="100" justify="center">
                <h2 className='section-title'>
                    <Translator tid="HOME.TITLE_SECTION_OTHER_PROJECTS" />
                </h2>
                <OthersProjectsList projects={othersProjects} />
            </Container>
        </Container>
    )
}

export default Home
