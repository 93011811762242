import '../../sass/components/cards/_otherProject.scss'
import Container, { ContainerFlex, If } from '../Containers'
import IconButton from '../IconButton'
import NavigatorPreview from '../NavigatorPreview'
import TechnosList from '../TechnosList'
import icon_github from '../../icons/github.svg'
import icon_gitlab from '../../icons/gitlab.svg'
import icon_share from '../../icons/share.svg'

function OtherProjectCard({ project, color }) {
    const _color = ['color-project-1', 'color-project-2', 'color-project-3'].includes(color) ? color : 'color-project-1';

    return (
        <Container className={`other-project-card ${_color}`} gap="1">
            <Container className="other-project-card__infos" width="100">
                <h3 className="project-name">{project.name}</h3>
                <h4 className="project-role">{project.role}</h4>
            </Container>

            <Container className="other-project-card__image" width="100" justify="center">
                <NavigatorPreview images={project.pictures} />
            </Container>

            <ContainerFlex width="100" justify="right" align="center" className="other-project-card__actions" gap="1">
                <div>
                    <TechnosList technos={project.technologies} />
                </div>

                <If IF={project?.github !== null}>
                    <a href={project.github} target="_blank" rel="noreferrer">
                        <IconButton color={color} icon={icon_github} className="action" />
                    </a>
                </If>
                <If IF={project?.gitlab !== null}>
                    <a href={project.gitlab} target="_blank" rel="noreferrer">
                        <IconButton color={color} icon={icon_gitlab} className="action" />
                    </a>
                </If>
                <If IF={project?.website !== null}>
                    <a href={project.website} target="_blank" rel="noreferrer">
                        <IconButton color={color} icon={icon_share} className="action" />
                    </a>
                </If>
            </ContainerFlex>
        </Container >
    );
}

export default OtherProjectCard;