import cv from "../img/CV Dylan Stienne.pdf";
import squirle_main from "../img/projects/squirle/main.png";
import portfolio_main from "../img/projects/portfolio/main.png";
import codetonexo_main from "../img/projects/codetonexo/main.png";
import devcoin_main from "../img/projects/devcoin/main.png";
import escape34_main from "../img/projects/escape34/main.png";
import wedding_website_main from "../img/projects/wedding_website/main.png";
import template_marketing_1_main from "../img/projects/template_marketing_1/main.png";
import template_marketing_2_main from "../img/projects/template_marketing_2/main.png";
import dreamliner_agency_v1_main from "../img/projects/dreamliner_agency_v1/main.png";
import dreamliner_agency_main from "../img/projects/dreamliner_agency/main.png";
import dreamliner_todo_main from "../img/projects/dreamliner_todo/main.png";
import portfolio_lea_marester_main from "../img/projects/portfolio_lea_marester/main.png";
import heiven_main from "../img/projects/heiven/main.png";
import marshall_motors_main from "../img/projects/marshall_motors/main.png";
import mon_fresh_juice_main from "../img/projects/mon_fresh_juice/main.png";
import portfolio_priscilla_stienne_main from "../img/projects/portfolio_priscilla_stienne/main.png";
import adstrack_main from "../img/projects/adstrack/main.png";
import adstrack_v1_main from "../img/projects/adstrack_v1/main.png";
import festif_main from "../img/projects/festif/main.png";
import avisun_main from "../img/projects/avisun/main.png";
import amplify_main from "../img/projects/amplify/main.png";

export default {
  getMyInfos() {
    return {
      name: "Dylan Stienne",
      description:
        "CTO On-demand, Développeur full-stack & Co-founder de l’agence Dreamliner, je vous aide dans toutes les phases stratégiques et techniques de vos projets.",
      phone: "06 50 78 48 62",
      email: "dylan@amplify-consulting.fr",
      "src-cv": cv,
      "url-linkedin": "https://www.linkedin.com/in/dylan-stienne-8807981bb/",
      "url-github": "https://github.com/dylan-stienne",
      "url-gitlab": "https://gitlab.com/dylan-stienne",
    };
  },
  getMySkills() {
    return {
      high: ["AWS", "Terraform", "Python", "Nuxt"],
      medium: ["Node", "SQL", "Vue", "React"],
      low: ["Jira", "Git", "Tailwind", "CICD"],
    };
  },
  getMyBestProjects() {
    return [
      {
        name: "Amplify Consulting",
        role: "Fondateur",
        description:
          "Amplify Consulting est mon agence de consulting technique de pointe ou je propose mes services de CTO on-demand.\n J'interviens dans les petites et moyennes structures pour les accompagner dans leur transformation digitale et leur permettre de se développer grâce à des solutions techniques adaptées à leurs besoins.",
        website: "https://amplify-consulting.fr",
        github: null,
        gitlab: null,
        technologies: ["Nuxt", "Tailwind", "Vercel", "Prismic"],
        pictures: [amplify_main],
      },
      {
        name: "Dreamliner | Agence",
        role: "Fondateur",
        description:
          "L'agence Dreamliner est une agence digitale fondée par moi-même et deux associés. Notre agence permet aux clients de créer leur propre site internet en leur fournissant des services sur mesure.\nQue vous souhaitiez un site web personnel, professionnel ou e-commerce, nous sommes là pour répondre à vos besoins.",
        website: "https://www.dreamliner.fr",
        github: null,
        gitlab: null,
        technologies: ["Nuxt", "Tailwind", "MongoDB", "AWS", "Terraform"],
        pictures: [dreamliner_agency_main],
      },
      {
        name: "Carrefour énergies - Avisun",
        role: "Développeur full-stack",
        description: 
          "C'est un projet de site vitrine pour une entreprise de panneaux solaires propulsé par une offre de Carrefour. \nJ'ai participé à ce projet en tant que développeur full-stack, j'ai donc réalisé le front-end en Angular et participé à la mise en place d'un back-office Prismic et d'un pré-rendering pour optimiser le SEO.",
        website: "https://energies.carrefour.fr/",
        github: null,
        gitlab: null,
        technologies: ["Angular", "Sass", "Prismic", "AWS"],
        pictures: [avisun_main],
      },
    ];
  },
  getMyOthersProjects() {
    return [
      {
        name: "squirle",
        role: "Développeur full-stack",
        description:
          "Squirle est un projet d’une WEB application de management destinée aux entreprises auquel j’ai participé dès sa création.\n Ma mission reposait principalement sur la mise en place de la base de données MongoDB et le développement de l’API Python via des Lambdas AWS déployées par Terraform ainsi que sur la résolution de petits bugs sur le front Angular de la plateforme.",
        website: null,
        github: null,
        gitlab: null,
        technologies: ["Python", "Nuxt", "Tailwind", "MongoDB", "AWS"],
        pictures: [squirle_main],
      },
      {
        name: "Adstrack",
        role: "Développeur full-stack",
        description: null,
        website: null,
        github: null,
        gitlab: null,
        technologies: ["Nuxt", "Tailwind", "Chart.js", "Python", "SQL", "AWS"],
        pictures: [adstrack_main],
      },
      {
        name: "portfolio Léa Marester",
        role: "Développeur full-stack",
        description: null,
        website: "https://lea-marester.netlify.app",
        github: null,
        gitlab: null,
        technologies: ["Nuxt", "Tailwind", "Netlify"],
        pictures: [portfolio_lea_marester_main],
      },
      {
        name: "Mon Fresh Juice",
        role: "Développeur full-stack",
        description: null,
        website: null,
        github: null,
        gitlab: null,
        technologies: ["Shopify", "Git"],
        pictures: [mon_fresh_juice_main],
      },
      {
        name: "Festif",
        role: "Développeur full-stack",
        description: null,
        website: null,
        github: null,
        gitlab: null,
        technologies: ["Nuxt", "Python", "AWS"],
        pictures: [festif_main],
      },
      {
        name: "template marketing",
        role: "Intégrateur Front",
        description:
          "Le but de ce projet a été d'intégrer une maquette d'une home page via la technologie Sass, le second défi était d'heberger cette template via netlify. \nLa template est donc en ligne mais elle n'est pas fonctionnelle, elle est seulement constituée d'une page d'accueil puisque son développement n'avait que pour but de me faire découvrir de nouveaux outils.",
        website: "https://ds-template-2.netlify.app",
        github: null,
        gitlab: null,
        technologies: ["Sass", "Gulp", "Netlify"],
        pictures: [template_marketing_2_main],
      },
      {
        name: "Adstrack v1",
        role: "Développeur front-end",
        description: null,
        website: null,
        github: null,
        gitlab: null,
        technologies: ["Nuxt", "Tailwind", "chart.js"],
        pictures: [adstrack_v1_main],
      },
      {
        name: "template marketing",
        role: "Designer & développeur",
        description:
          "Ce projet avait pour but la création d'une template d'un site marketing avec, en parallèle, l'explication de toutes les étapes de création via des posts instagram sur mon compte @dylan.stienne.pro ! \nLa template a été réalisée en Javascript et en Sass. \nLa preview de la version finale est d'ailleurs toujours disponible en ligne !",
        website: "https://ds-template-1.netlify.app",
        github: null,
        gitlab: null,
        technologies: ["Html", "Sass", "JavaScript", "Netlify"],
        pictures: [template_marketing_1_main],
      },
      {
        name: "Dreamliner | Todo",
        role: "Développeur full-stack",
        description: null,
        website: "https://app.dreamliner.fr",
        github: null,
        gitlab: null,
        technologies: ["Python", "Nuxt", "MongoDB", "AWS"],
        pictures: [dreamliner_todo_main],
      },
      {
        name: "Dreamliner | Agence v1",
        role: "Développeur full-stack",
        description: null,
        website: "https://www.dremliner.fr",
        github: null,
        gitlab: null,
        technologies: ["Nuxt", "Tailwind"],
        pictures: [dreamliner_agency_v1_main],
      },
      {
        name: "Portfolio Priscilla Stienne",
        role: "Développeur full-stack",
        description: null,
        website: "https://priscilla-stienne.fr",
        github: null,
        gitlab: null,
        technologies: ["Nuxt", "Airtable"],
        pictures: [portfolio_priscilla_stienne_main],
      },
      {
        name: "Marshall motors",
        role: "Chef de projet",
        description: null,
        website: null,
        github: null,
        gitlab: null,
        technologies: ["Symfony", "MySql", "Git"],
        pictures: [marshall_motors_main],
      },
      {
        name: "portfolio",
        role: "Développeur full-stack",
        description:
          "Mon portfolio, le site que vous consultez actuellement, est un projet personnel réalisé en React en quelques jours, avec de l’intégration continue via Gitlab et Netlify. \n Il ne possède pas de back office ni de base de données, car son contenu n’est pas voué à changer régulièrement. \n Le Gitlab du projet est disponible si vous souhaitez analyser le code.",
        website: null,
        github: null,
        gitlab: "https://gitlab.com/dylan-stienne/portfolio",
        technologies: ["React", "Sass", "Netlify"],
        pictures: [portfolio_main],
      },
      {
        name: "codetonexo",
        role: "Développeur full-stack",
        description:
          "CodeTonExo est un projet personnel d’un site d’exercices de programmation pour débutants initialement prévu pour aider mes camarades de classe durant mes études supérieures à Supinfo. \n J’ai créé ce site entièrement moi-même en Php, Html5 et MySql sur environ 6 mois de temps en parallèle de mes cours, néanmoins il a été mon premier projet réel donc il reste beaucoup de points à améliorer notamment le design.",
        website: "https://codetonexo.com",
        github: null,
        gitlab: null,
        technologies: ["Php", "Html5", "MySql"],
        pictures: [codetonexo_main],
      },
      {
        name: "Heiven.co",
        role: "Chef de projet",
        description: null,
        website: "https://www.heiven.co",
        github: null,
        gitlab: null,
        technologies: ["Shopify", "Git"],
        pictures: [heiven_main],
      },
      {
        name: "site de mariage",
        role: "Designer & développeur",
        description:
          "Réalisation d'un site mobile pour un mariage sur le thème du voyage. \nAu menu : \n- Un quizz sur les mariés pour animer la soirée \n- Un album photo sur lequel les invités peuvent déposer leurs meilleurs clichés \n- Un livre d'or pour laisser un petit mot aux mariés \n- Le programme pour suivre étape par étape le déroulement du mariage.",
        website: null,
        github: null,
        gitlab: null,
        technologies: ["Symfony", "MySql", "Git"],
        pictures: [wedding_website_main],
      },
      {
        name: "devcoin",
        role: "Lead développeur",
        description:
          "Devcoin est un projet ambitieux qui a pour but d'être un forum gamifié d'entraide étudiants au sein du pôle universitaire Léonard De Vinci mais il n'est pas encore accessible pour le public.\n Ce projet a nécessité beaucoup de developpement du côté Back-end comme Front-end sur lesquels j'ai activement participé tout en endossant la casquette de lead développeur du projet.",
        website: null,
        github: null,
        gitlab: null,
        technologies: ["Python", "React.js", "MongoDB", "Sass"],
        pictures: [devcoin_main],
      },
      {
        name: "escape34",
        role: "Développeur Front",
        description:
          "Un site pour résoudre un escape game ? Et oui, si vous décidez de vous y rendre vous trouverez surement le site très basique, c'est le but ! \nUne page cachée sur le thème d'halloween se trouve derière le bouton 'se connecter' du menu. \nMon défi était de designer, développer et héberger ce site en moins de 3 jours. \nL'escape game n'est, quant à lui, malheureusement plus disponible.",
        website: "https://escape-34.netlify.app",
        github: null,
        gitlab: null,
        technologies: ["Nuxt", "Tailwind", "Netlify"],
        pictures: [escape34_main],
      },
    ];
  },
};
