import BestProjectCard from './cards/BestProject'
import Container from './Containers';

function BestProjectsList({ projects }) {
    return <Container gap="3" justify="center" className='best-projects-list'>
        {projects.map((project, i) => {
            return <BestProjectCard key={i} project={project} color={`color-project-${i % 3 + 1}`} />
        })}
    </Container>
}

export default BestProjectsList;
