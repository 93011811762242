import '../../sass/components/cards/_portfolio.scss';
import IconButton from '../../components/IconButton';
import { ContainerFlex } from '../../components/Containers';
import icon_arrow_right from '../../icons/arrow-right.svg';
import Translator from '../../services/translate';

function PortfolioCard({ onClick = () => {}}) {
    return <div className="portfolio-card" onClick={onClick}>
        <h2><Translator tid="HOME.TITLE_MY_PORTFOLIO" /></h2>
        <ContainerFlex justify="between" align="center">
            <p><Translator tid="HOME.LABEL_MY_PORTFOLIO" /></p>
            <IconButton className="icon-button" color="accent-1" icon={icon_arrow_right} />
        </ContainerFlex>
    </div>
}

export default PortfolioCard;

